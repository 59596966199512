import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import Switch from '@mui/material/Switch';

const DesignComponent = ({ onDesignChange, onVoiceChange, onPaymentRequirement, onSignificantChange }) => {
  const [themeColor, setThemeColor] = useState('#FF6900');
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [showCompanyTag, setShowCompanyTag] = useState(true);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState('Alloy');

  const handleColorChange = (color) => {
    setThemeColor(color.hex);
    onDesignChange({
      theme: { backgroundColor: color.hex },
      playPauseButton: {
        color: color.hex,
        background: isDarkMode ? '#282828' : 'white'
      },
      progressBar: { backgroundColor: color.hex },
    });
    onPaymentRequirement(true, 'price_1OwoqMCEY8Ej5Z6HlVy2EwSv');
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    onDesignChange({
      audioPlayer: { background: isDarkMode ? 'white' : '#282828' }, // Switches between light and dark
      playPauseButton: { color: themeColor, background: isDarkMode ? 'white' : '#282828' },
      time: { color: isDarkMode ? 'black' : 'white' }, // Text color based on mode
      progressContainer: { background: isDarkMode ? '#0000001f' : '#ffffff29' },
    });
    onPaymentRequirement(true, 'price_1OwoqMCEY8Ej5Z6HSrbmWrlk');
  };

  const toggleCompanyTag = () => {
    const newShowCompanyTag = !showCompanyTag;
    setShowCompanyTag(newShowCompanyTag);
    onDesignChange({ showCompanyTag: newShowCompanyTag });
    onPaymentRequirement(true, newShowCompanyTag ? 'price_1Ow4dSCEY8Ej5Z6HHUu1DjM6' : 'price_1OwQVKCEY8Ej5Z6HmW8oKfpg');
  };

  const handleVoiceChange = (event) => {
    const newVoice = event.target.value;
    setSelectedVoice(newVoice); 
    onVoiceChange(newVoice);

    onSignificantChange();
  };

  
  const handleClick = () => setDisplayColorPicker(!displayColorPicker);
  const handleClose = () => setDisplayColorPicker(false);

  return (
    <div className="design-component">
      <h2>Customize Player</h2>
      <div className="design-option">
        <label>Voice Option:</label>
        <select className="voice-options" value={selectedVoice} onChange={handleVoiceChange}>
          {['Alloy', 'Echo', 'Fable', 'Onyx', 'Nova', 'Shimmer'].map(voice => (
            <option key={voice} value={voice}>{voice}</option>
          ))}
        </select>
      </div>
      <div className="design-option">
        <label>Theme Color:</label>
        <div className="color-boxes">
          {['#FF0000', '#00FF00', '#0000FF'].map(color => (
            <div key={color} className="color-box" style={{ backgroundColor: color }} onClick={() => handleColorChange({ hex: color })} />
          ))}
        </div>
        <button className="color-picker-button" onClick={handleClick}>Choose Color</button>
        {displayColorPicker && (
          <div className="popover">
            <div className="cover" onClick={handleClose}></div>
            <SketchPicker color={themeColor} onChangeComplete={handleColorChange} />
          </div>
        )}
      </div>
      <div className="design-option">
        <label>Dark Mode:</label>
        <Switch checked={isDarkMode} onChange={toggleDarkMode} />
      </div>
      <div className="design-option">
        <label>Show Company Tag:</label>
        <Switch checked={showCompanyTag} onChange={toggleCompanyTag} />
      </div>
    </div>
  );
};

export default DesignComponent;
