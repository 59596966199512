// Dashboard.jsx

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import SideNav from '../components/navigation/SideNav';
import DashboardHeader from '../components/navigation/DashboardHeader';
import PriceTableModal from '../components/priceTable'; // Adjust the import path as necessary
import './Dashboard.css';
import { Outlet } from 'react-router-dom';

const Dashboard = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useLocation(); 
    let title;

    // Determine the title based on the current route
    switch (location.pathname) {
        case '/':
            title = 'Home';
            break;
        case '/playground':
            title = 'Design';
            break;
        case '/installation':
            title = 'Install';
            break;
        case '/settings':
            title = 'Settings'
            break;
        default:
            title = 'Dashboard'; // Default title
    }

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="dashboard">
            <SideNav />
            <div className="dashboard-content">
                <DashboardHeader title={title} onUpgradeClick={handleOpenModal} /> {/* Use title here */}
                <Outlet />
                <PriceTableModal isOpen={isModalOpen} onClose={handleCloseModal} />
            </div>
        </div>
    );
}

export default Dashboard;
