// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB1pQcMftqX4m4KK2dTd3o5Ntdvu_UwG2s",
  authDomain: "butter-reader.firebaseapp.com",
  projectId: "butter-reader",
  storageBucket: "butter-reader.appspot.com",
  messagingSenderId: "722661596323",
  appId: "1:722661596323:web:6696ab6e11503e7250f316",
  measurementId: "G-3TN8EKHMX5"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const firestore = getFirestore(app);

export { app, auth, firestore };