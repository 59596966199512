import { createContext, useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthPage from './pages/AuthPage';
import HomePage from './pages/HomePage';
import Dashboard from './components/Dashboard';
import Playground from './pages/Playground';
import Install from './pages/InstallationGuidePage'
import DemoPage from './components/website/DemoPage'
import Setting from './pages/Settings'
import { AudioPlayerProvider } from './components/AudioPlayerContext';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { I18nextProvider } from 'react-i18next';
import { getUserPlanAndUsage } from './components/planService'
import i18n from './i18n';
export const UserPlanContext = createContext(null);

function App() {
  const [user, setUser] = useState(null);
  const [userPlan, setUserPlan] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        getUserPlanAndUsage().then(planDetails => {
          setUserPlan(planDetails.planName); // Store the full plan name
        });
      }
    });
    return unsubscribe;
  }, []);

  return (
    <UserPlanContext.Provider value={userPlan}>
      <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route path="/login" element={user ? <Navigate to="/" /> : <AuthPage />} />
          <Route path="/" element={user ? <AudioPlayerProvider><Dashboard /></AudioPlayerProvider> : <Navigate to="/login" />} >
            <Route index element={<HomePage />} />
            <Route path="playground" element={<Playground />} />
            <Route path="Installation" element={<Install />} />
            <Route path="settings" element={<Setting />} />
          </Route>
          <Route path="/demo" element={<DemoPage />} />
        </Routes>
      </Router>
      </I18nextProvider>
    </UserPlanContext.Provider>
  );
}

export const useUserPlan = () => useContext(UserPlanContext);

export default App;
