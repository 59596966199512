// InstallationPage.js
import React from 'react';
import InstallationInstructions from '../components/install/install.js';

const InstallationPage = () => {
  return (
    <div className="installation-page">
      <InstallationInstructions />
    </div>
  );
};

export default InstallationPage;
