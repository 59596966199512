// PreviewPage.js
import React, { useContext, useState } from 'react';
import AudioPlayer from '../AudioPlayer'; // Adjust the import path as necessary
import { AudioPlayerContext } from '../AudioPlayerContext'; // Adjust the import path as necessary

const PreviewPage = ({ selectedVoiceAudio }) => {
  const { customStyles } = useContext(AudioPlayerContext);

  return (
    <div className="preview-page">
      <div className="live-view">
        {/* Use the selected voice's audio */}
        <AudioPlayer audioSrc={selectedVoiceAudio} />
        {/* The AudioPlayer will automatically use the styles from AudioPlayerContext */}
      </div>
    </div>
  );
};

export default PreviewPage;
