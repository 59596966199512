import { auth, firestore } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs, setDoc, orderBy } from 'firebase/firestore';

const GOD_MODE_USER_ID = 'ZSRsojC6vPTqmCbFaojNknN611G2';

const priceIdToPlanIdMap = {
    'price_1Oagh2CEY8Ej5Z6Hr4zSXlnJ': 'pro',
    'price_1Oagh9CEY8Ej5Z6HST0mDo0j': 'premium'
};

const fetchPlanDetails = async (planId) => {
    if (!planId) {
        console.error("Plan ID is undefined");
        return null;
    }
    const planRef = doc(firestore, 'plans', planId);
    const planDoc = await getDoc(planRef);

    if (!planDoc.exists()) {
        console.error(`Plan document not found for ID: ${planId}`);
        return null;
    }
    return planDoc.data();
};

export const getUserPlanAndUsage = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
        console.error('No user is currently logged in');
        return;
    }

    // Check if the user is the God Mode user
    if (currentUser.uid === GOD_MODE_USER_ID) {
        return {
            planName: 'God Level',
            visitorLimit: 'Unlimited',
            visitorsReached: 'Unlimited',
            isPremium: true
        };
    }

    const userRef = doc(firestore, 'customers', currentUser.uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
        console.error('Customer document not found, assigning default free plan');
        await setDoc(userRef, { planId: 'free', audioGeneratedCount: 0 });
        return { planName: 'free', visitorLimit: 10, visitorsReached: 0, isPremium: false };
    }

    const userData = userDoc.data();
    const subscriptionsRef = collection(firestore, 'customers', currentUser.uid, 'subscriptions');
    const activeSubscriptionQuery = query(subscriptionsRef, where("status", "==", "active"), orderBy('created', 'desc'));
    const subscriptionSnapshots = await getDocs(activeSubscriptionQuery);

    let planId = 'free';
    let latestActiveSubscription = null;
    subscriptionSnapshots.forEach((docSnapshot) => {
        if (!latestActiveSubscription || docSnapshot.data().created > latestActiveSubscription.created) {
            latestActiveSubscription = docSnapshot.data();
        }
    });

    if (latestActiveSubscription && latestActiveSubscription.items && latestActiveSubscription.items.length > 0) {
        const latestItem = latestActiveSubscription.items[0];
        const priceId = latestItem.price.id;
        planId = priceIdToPlanIdMap[priceId] || 'free';
    }

    if (userData.planId !== planId) {
        await setDoc(userRef, { planId }, { merge: true });
    }

    let planDetails = planId !== 'free' ? await fetchPlanDetails(planId) : null;

    return {
        planName: planDetails ? planDetails.name : 'free',
        visitorLimit: planDetails ? planDetails.audioDownloadLimit : 10,
        visitorsReached: userData.audioGeneratedCount || 0,
        isPremium: planId !== 'free'
    };
};

