import React, { useContext, useState, useEffect } from 'react';
import TopBar from '../components/playground/TopBar';
import Design from '../components/design/Design';
import PreviewPage from '../components/design/PreviewPage';
import ControlPanel from '../components/playground/ControlPanel';
import alloyBlogAudio from '../assets/audio/alloy-blog.mp3';
import { AudioPlayerContext } from '../components/AudioPlayerContext';
import { auth, firestore } from '../firebase';
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getUserPlanAndUsage } from '../components/planService'; 
import './Playground.css';

function Playground() {
  const { customStyles, setCustomStyles, voiceSettings, setVoiceSettings } = useContext(AudioPlayerContext);
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    const fetchUserSettingsAndPlan = async () => {
        try {
            const userId = auth.currentUser.uid;
            const stylesRef = doc(firestore, 'userStyles', userId);
            const docSnap = await getDoc(stylesRef);

            if (docSnap.exists()) {
                const savedSettings = docSnap.data();
                setCustomStyles(savedSettings.customStyles || customStyles);
                setVoiceSettings(savedSettings.voiceSettings || voiceSettings);
            } else {
                setVoiceSettings({ name: 'Alloy', audioSrc: alloyBlogAudio });
            }

            const planData = await getUserPlanAndUsage();
            if (planData) {
                setCanEdit(planData.planName.toLowerCase() !== 'free');
            }
        } catch (error) {
            console.error("Error fetching user settings and plan:", error);
        }
    };

    fetchUserSettingsAndPlan();
  }, [setCustomStyles, setVoiceSettings]);


  const handleSaveDesign = async (design) => {
    const userId = auth.currentUser.uid;
    const stylesRef = doc(firestore, 'userStyles', userId);
    await setDoc(stylesRef, { ...customStyles, ...design });
  };

  const handleDesignChange = (newStyles) => {
    setCustomStyles(prev => ({ ...prev, ...newStyles }));
  };

  const handleVoiceChange = (newVoice) => {
    const newVoiceFile = `${newVoice}-blog.mp3`; // Constructs the file name based on voice selection
    setVoiceSettings({ name: newVoice, audioSrc: newVoiceFile });
  };

  return (
    <div className="Custom">
      <TopBar />
      <div className="playground">
        <div className="left-panel">
          <PreviewPage selectedVoiceAudio={voiceSettings.audioSrc} />
          <Design onSaveDesign={handleSaveDesign} onVoiceChange={handleVoiceChange} canEdit={canEdit} />
        </div>
        <div className="right-panel">
          <ControlPanel />
        </div>
      </div>
    </div>
  );
}

export default Playground;
