import React, { useContext, useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import {Switch} from '@mui/material';
import alloyBlogAudio from '../../assets/audio/alloy-blog.mp3';
import echoBlogAudio from '../../assets/audio/echo-blog.mp3';
import fableBlogAudio from '../../assets/audio/fable-blog.mp3';
import onxyBlogAudio from '../../assets/audio/onyx-blog.mp3';
import novaBlogAudio from '../../assets/audio/nova-blog.mp3';
import shimmerBlogAudio from '../../assets/audio/shimmer-blog.mp3';
import { AudioPlayerContext } from '../AudioPlayerContext';
import { TroubleshootRounded } from '@mui/icons-material';
import PriceTableModal from '../priceTable'; 

function Design({ onSaveDesign, canEdit, userPlan }) {
  const { customStyles, setCustomStyles, voiceSettings, setVoiceSettings } = useContext(AudioPlayerContext);
  
  const [isDarkMode, setIsDarkMode] = useState(TroubleshootRounded);

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [themeColor, setThemeColor] = useState(customStyles?.playPauseButton?.color || 'defaultColor');
  const [showCompanyTag, setShowCompanyTag] = useState(customStyles?.showCompanyTag ?? true);
  const [saveStatus, setSaveStatus] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); 

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const voices = {
    Alloy: alloyBlogAudio,
    Echo: echoBlogAudio,
    Fable: fableBlogAudio,
    Onyx: onxyBlogAudio,
    Nova: novaBlogAudio,
    Shimmer: shimmerBlogAudio,
  };

  useEffect(() => {
    if (customStyles?.audioPlayer?.background === '#282828') {
      setIsDarkMode(true);
    }
    if (customStyles && typeof customStyles.showCompanyTag !== 'undefined') {
      setShowCompanyTag(customStyles.showCompanyTag);
    }
  }, [customStyles]);

  const handleCompanyTagToggle = (event) => {
    if (canEdit) {
      setCustomStyles(prevStyles => ({
        ...prevStyles,
        showCompanyTag: event.target.checked
      }));
      setShowCompanyTag(event.target.checked);
    }
  };

  const handleToggleDarkMode = (event) => {
    const newIsDarkMode = event.target.checked;
    setIsDarkMode(newIsDarkMode);
    const newStyles = {
      audioPlayer: { background: newIsDarkMode ? '#282828' : 'white' },
      playPauseButton: { color: themeColor, background: newIsDarkMode ? '#282828' : 'white' },
      progressBar: { backgroundColor: themeColor },
      theme: { backgroundColor: themeColor },
      time: { color: newIsDarkMode ? 'white' : 'black' },
      progressContainer: { background: newIsDarkMode ? '#ffffff29' : '#0000001f' },
    };
    setCustomStyles(newStyles);
  };

  const handleVoiceChange = (event) => {
    const voiceName = event.target.value;
    const audioSrc = voices[voiceName];
    setVoiceSettings({ name: voiceName, audioSrc: audioSrc });
  };

  const saveDesign = () => {
    onSaveDesign({
      ...customStyles, 
      voiceSettings, 
      showCompanyTag 
    });
    setSaveStatus('Saving...');
    setTimeout(() => setSaveStatus('Design saved!'), 500);
  };
  

  const handleColorChange = color => {
    setThemeColor(color.hex);
    setCustomStyles(prevStyles => ({
      ...prevStyles,
      playPauseButton: { ...prevStyles.playPauseButton, color: color.hex },
      progressBar: { backgroundColor: color.hex },
      theme: { backgroundColor: color.hex },
    }));
  };

  useEffect(() => {
    if (customStyles && typeof customStyles.showCompanyTag !== 'undefined') {
      setShowCompanyTag(customStyles.showCompanyTag);
    }
  }, [customStyles]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  if (!canEdit) {
    return (
      <div className="design-component restricted">
        <div className="upgrade-prompt">
          <button className="upgrade-button" onClick={handleOpenModal}>
            Upgrade Plan
          </button>
          <PriceTableModal 
            isOpen={isModalOpen} 
            onClose={handleCloseModal} 
            userPlan={userPlan} 
            onPaymentSuccess={() => {/* Handle payment success */}}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="design-component">
      <h2>Customize Your Widget</h2>
      <div className="questions">
        <div className="question">
          <label>Voice Option:</label>
          <select className="voice-options" value={voiceSettings.name} onChange={handleVoiceChange}>
            {Object.keys(voices).map(voice => (
              <option key={voice} value={voice}>{voice}</option>
            ))}
          </select>
        </div>
        <div className="question">
          <label>Select Color:</label>
          <div className="color-boxes">
            {['#FF0000', '#00FF00', '#0000FF'].map(color => (
              <div key={color} className="color-box" style={{ backgroundColor: color }} onClick={() => handleColorChange({ hex: color })} />
            ))}
            <button className="color-picker-button" onClick={handleClick}>Choose Color</button>
            {displayColorPicker && (
              <div className="popover">
                <div className="cover" onClick={handleClose} />
                <SketchPicker color={themeColor} onChange={handleColorChange} />
              </div>
            )}
          </div>
        </div>
        <div className="question">
        <label>Dark Mode:</label>
        <Switch 
          checked={isDarkMode} 
          onChange={handleToggleDarkMode} 
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: '#FFD600',
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: '#FFD600',
            },
          }}
        />
      </div>
      <div className="question">
        <label>Show Logo:</label>
        <Switch 
          checked={showCompanyTag} 
          onChange={handleCompanyTagToggle} 
          disabled={!canEdit} 
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#FFD600',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#FFD600',
              },
            }}
          />
        </div>
      </div>
      <button className="save-button" onClick={saveDesign}>Save Design</button>
      {saveStatus && <div>{saveStatus}</div>}
    </div>
  );
}

export default Design;