import React from 'react';
import LiveDemo from './demo/LiveDemo'; // Update the import path

const ControlPanel = () => {
  return (
    <div className="mcdonalds-page">
      <div className="backdrop"></div>
      <div className="content-container">
        <LiveDemo />
      </div>
    </div>
  );
};

export default ControlPanel;
