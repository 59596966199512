import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DemoLogo from '../assets/butterreader-logo.svg';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { doc, setDoc, getDocs, collection, query, where } from "firebase/firestore"; // import getDocs, collection, query, where
import { firestore } from '../firebase';
import googleLogo from '../assets/google.svg';
import './AuthPage.css';

const AuthPage = () => {
  const { t } = useTranslation();
  const [isLogin, setIsLogin] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState(''); 
  const [showResetPassword, setShowResetPassword] = useState(false);

  const auth = getAuth();

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      // Check if user is new
      if (userCredential.additionalUserInfo.isNewUser) {
        await setDoc(doc(firestore, 'customers', user.uid), {
          planId: 'free' // or your default plan
        });
      }
      // User is signed in, redirect to home page
    } catch (error) {
      console.error('Google Sign Up Error:', error.message);
    }
  };

  const handleEmailPasswordSignUp = async () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      // Create a document for the new user with a default plan
      await setDoc(doc(firestore, 'customers', user.uid), {
        planId: 'free' // or your default plan
      });
    } catch (error) {
      console.error('Email Password Sign Up Error:', error.message);
      setError(error.message);
    }
  };

  const handleEmailPasswordLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // User is logged in, redirect to home page
    } catch (error) {
      console.error('Email Password Login Error:', error.message);
      setError(error.message);
    }
  };

  const handleForgotPassword = async () => {
    if (!resetPasswordEmail) {
      setForgotPasswordError("Please enter your email address.");
      return;
    }
  
    try {
      const response = await fetch(`https://us-central1-butter-reader.cloudfunctions.net/checkCustomerEmail?email=${encodeURIComponent(resetPasswordEmail)}`);
      const data = await response.json();
  
      if (!data.exists) {
        setForgotPasswordError("No account found with the given email.");
        return;
      }
  
      await sendPasswordResetEmail(auth, resetPasswordEmail);
      setResetPasswordEmail('');
      setShowResetPassword(false);
      alert('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setForgotPasswordError("Failed to check email. Please try again later.");
    }
  };
  

  return (
    <div className="auth-container">
      <div className="login-side">
        <div className="login-card">
        <img src={DemoLogo} alt={t('iconLogo')} className="logo" />
          {isLogin ? (
            <>
              <h1>Welcome Back</h1>
              <label className='auth-label'>Email</label>
              <input
                  className="input-login"
                  type="email"
                  placeholder='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
              />
              <label className='auth-label'>Password</label>
              <input
                  className="input-login"
                  type="password"
                  placeholder='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
              />
            </>
          ) : (
            <>
              <h1>Create New Account</h1>
              <p>No credit card required</p>
              <label className='auth-label'>Full Name</label>
              <input
                  className="input-sign"
                  type="text"
                  placeholder='name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
              />
              <label className='auth-label'>{t('email_address')}</label>
              <input
                  className="input-sign"
                  type="email"
                  placeholder='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
              />
              <label className='auth-label'>Password</label>
              <input
                  className="input-sign"
                  type="password"
                  placeholder='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
              />
              <label className='auth-label'>Confirm Password</label>
              <input
                  className="input-sign"
                  type="password"
                  placeholder='confirm password'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div className="terms-checkbox">
                <input
                  type="checkbox"
                  checked={agreeToTerms}
                  onChange={(e) => setAgreeToTerms(e.target.checked)}
                />
                <span>{t('agree_to_terms')}</span>
              </div>
            </>
          )}

          {error && <div className="error-message">{error}</div>}

          {isLogin ? (
            <div className="login-actions">
              <button className="login-button" onClick={handleEmailPasswordLogin}>
                Login
              </button>
              <a href="#" onClick={() => setShowResetPassword(true)} className="forgot-password-link">
                Forgot password?
              </a>
            </div>
          ) : (
            <button className="login-button" onClick={handleEmailPasswordSignUp}>
              Sign Up
            </button>
          )}

          <div className="or-divider">
              <span>{t('or')}</span>
          </div>

          <button onClick={handleGoogleSignUp} className="google-button">
              <img src={googleLogo} alt="Google Logo" className="google-logo" />
              {t('sign_up_with_google')}
          </button>

          {isLogin ? (
            <span>Don't have an account yet? <a href="#" onClick={() => setIsLogin(false)}>Sign Up</a></span>
          ) : (
            <span>Already have an account? <a href="#" onClick={() => setIsLogin(true)}>Login</a></span>
          )}

        </div>
      </div>
      <div className="artwork-section">
        {/* Artwork */}
      </div>
      {showResetPassword && (
        <div className="modal-overlay">
          <div className="modal-container">
            <h3>Reset Password</h3>
            <p>Enter your email address and we will send you a link to reset your password.</p>
            <input
              type="email"
              value={resetPasswordEmail}
              onChange={(e) => setResetPasswordEmail(e.target.value)}
              placeholder="Enter your email"
            />
            {forgotPasswordError && <div className="error-message">{forgotPasswordError}</div>} {/* Show forgot password error */}
            <div className="modal-actions">
              <button onClick={handleForgotPassword}>Send Reset Email</button>
              <button onClick={() => setShowResetPassword(false)} className="cancel-button">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthPage;