import React, { useRef, useContext, useEffect, useState } from 'react';
import { AudioPlayerContext } from './AudioPlayerContext';
import './AudioPlayer.css';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import { firestore, auth } from '../firebase';

function AudioPlayer({ audioSrc, onPlay, onStop }) {
  const { customStyles, isPlaying, setIsPlaying, setCustomStyles } = useContext(AudioPlayerContext);
  const audioRef = useRef(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.play().then(onPlay).catch((e) => console.error("Playback failed:", e));
    } else {
      audio.pause();
      onStop && onStop(currentTime);
    }
  }, [isPlaying, onPlay, onStop]);

  useEffect(() => {
    const fetchStyles = async () => {
      const userId = auth.currentUser.uid;
      const stylesRef = doc(firestore, 'userStyles', userId);

      try {
        const docSnap = await getDoc(stylesRef);
        if (docSnap.exists()) {
          setCustomStyles(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };

    fetchStyles();
  }, []);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      setDuration(audioRef.current.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audioRef.current.currentTime);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      onStop && onStop(audioRef.current.duration);
      recordPlayDuration(audioRef.current.duration);
    };

    const audio = audioRef.current;
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [onStop]);

  const handleProgressClick = (e) => {
    const audio = audioRef.current;
    const boundingRect = e.target.getBoundingClientRect();
    const clickedPosition = e.clientX - boundingRect.left;
    const newTime = (clickedPosition / boundingRect.width) * duration;
    audio.currentTime = newTime;
  };

  const formatTime = (time) => {
    const floorTime = Math.floor(time);
    const minutes = Math.floor(floorTime / 60);
    const seconds = floorTime % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const recordPlayDuration = async (duration) => {
    const userId = auth.currentUser.uid;
    const playDurationRef = doc(firestore, 'customers', userId, 'playDurations', new Date().toISOString());
    await setDoc(playDurationRef, { duration });
  };

  return (
    <div className="audio-player" style={customStyles.audioPlayer}>
      <audio ref={audioRef} src={audioSrc} preload="metadata" />
      <div className="audio-player__theme" style={customStyles.theme}>
        <button className="audio-player__play-pause-btn" onClick={() => setIsPlaying(!isPlaying)} style={customStyles.playPauseButton}>
          {isPlaying ? '||' : '►'}
        </button>
      </div>
      <div className="audio-player__progress-container" onClick={handleProgressClick} style={customStyles.progressContainer}>
        <div className="audio-player__progress-bar" style={{ ...customStyles.progressBar, width: `${(currentTime / duration) * 100}%` }}></div>
      </div>
      <div className="audio-player__time" style={customStyles.time}>{formatTime(currentTime)}</div>
    </div>
  );
}

export default AudioPlayer;
