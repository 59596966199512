import { createContext, useState } from 'react';
import alloyBlogAudio from '../assets/audio/alloy-blog.mp3';

export const AudioPlayerContext = createContext(null);

export const AudioPlayerProvider = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [customStyles, setCustomStyles] = useState({
    audioPlayer: {},
    playPauseButton: {},
    progressBar: {},
    theme: {},
    time: {}
  });
  const [voiceSettings, setVoiceSettings] = useState({
    name: 'Alloy', 
    audioSrc: alloyBlogAudio // Now correctly defined
  });

  return (
    <AudioPlayerContext.Provider value={{ customStyles, setCustomStyles, isPlaying, setIsPlaying, voiceSettings, setVoiceSettings }}>
      {children}
    </AudioPlayerContext.Provider>
  );
};
